import React, { PureComponent } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { FeatureGate } from 'react-feature-gate';
import { withTranslation } from 'react-i18next';
import PropTypes from 'prop-types';

import ContentBlock from '../common/ContentBlock';
import App from '../App';
import Header from '../common/Header';
import HeroHeadingContainer from '../common/HeroHeadingContainer';

class ServicesView extends PureComponent {

	static propTypes = {
		t: PropTypes.func,
	}

	render() {
		const { t } = this.props;
		return (
			<App
				header={<Header showLogoutButton={false} />}
				heroHeadingContainer={<HeroHeadingContainer />}
				content={
					<ContentBlock className='container'>
						<div className='row'>
							<div className={classNames('col-12', 'u-margin-default')}>
								<Link to='/aspa'>{t('services.servicesAdminService')}</Link>
							</div>
							<div className={classNames('col-12', 'u-margin-default')}>
								<Link to='/data'>{t('services.servicesData')}</Link>
							</div>
							<div className={classNames('col-12', 'u-margin-default')}>
								<Link to='/rights'>{t('services.servicesRights')}</Link>
							</div>
							<div className={classNames('col-12', 'u-margin-default')}>
								<Link to='/protection'>{t('services.servicesProtection')}</Link>
							</div>
							<FeatureGate feature='FEATURE_MONITORING'>
								<div className={classNames('col-12', 'u-margin-default')}>
									<Link to='/monitoring'>{t('services.servicesPeopleMonitoring')}</Link>
								</div>
							</FeatureGate>
							<FeatureGate feature='FEATURE_COMPANY_MONITORING'>
								<div className={classNames('col-12', 'u-margin-default')}>
									<Link to='/companies/monitoring'>{t('services.servicesCompanyMonitoring')}</Link>
								</div>
							</FeatureGate>
						</div>
					</ContentBlock>
				}
			/>
		);
	}

}


export default withTranslation(['miscellaneous'])(ServicesView);
