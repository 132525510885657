import React, { Suspense } from 'react';
import { Route, Switch, Redirect } from 'react-router-dom';
import Loadable from 'react-loadable';
import DocumentTitle from 'react-document-title';
import { withTranslation } from 'react-i18next';

import ServicesView from './ServicesView/ServicesView';
import Spinner from './common/Spinner';
import App from './App';
import Header from './common/Header';
import LogoutView from './common/LogoutView';
import features from '../features';
import HeroHeadingContainer from './common/HeroHeadingContainer';
import CampaignRoute from './CampaignRoute';

const SettingsServiceRoutes = Loadable({
	loader: () => import('./SettingsService/SettingsServiceRoutes'),
	loading: Spinner,
});

const AdminServiceRoutes = Loadable({
	loader: () => import('./AdminService/AdminServiceRoutes'),
	loading: Spinner, // TODO Make a proper loading screen
});

const DataServiceRoutes = Loadable({
	loader: () => import('./DataService/DataServiceRoutes'),
	loading: Spinner, // TODO Make a proper loading screen
});

const ProtectionServiceRoutes = Loadable({
	loader: () => import('./ProtectionService/ProtectionServiceRoutes'),
	loading: Spinner, // TODO Make a proper loading screen
});

const RightsServiceRoutes = Loadable({
	loader: () => import('./RightsService/RightsServiceRoutes'),
	loading: Spinner, // TODO Make a proper loading screen
});

const MonitoringServiceRoutes = Loadable({
	loader: () => import('./MonitoringService/MonitoringServiceRoutes'),
	loading: Spinner, // TODO Make a proper loading screen
});

const CompanyMonitoringServiceRoutes = Loadable({
	loader: () => import('./CompanyMonitoringService/CompanyMonitoringServiceRoutes'),
	loading: Spinner, // TODO Make a proper loading screen
});

let rootView;
if (process.env.NODE_ENV !== 'production') {
	// In development environments, show the service listing page in '/' path.
	rootView = (
		<Route
			path='/'
			exact
			component={ServicesView}
		/>
	);
}
else {
	// In production (and staging), redirect from '/' to the Data service.
	rootView = (
		<Redirect path='/' exact to='/data' />
	);
}

const Routes = () => (
	<DocumentTitle title='OmaData'>
		<Switch>
			{rootView}
			<Route
				path='/settings'
				component={SettingsServiceRoutes}
			/>
			<Route
				path='/aspa'
				component={AdminServiceRoutes}
			/>
			<Route
				path='/data'
				component={DataServiceRoutes}
			/>
			<Route
				path='/protection'
				component={ProtectionServiceRoutes}
			/>
			<Route
				path='/rights'
				component={RightsServiceRoutes}
			/>
			{features.FEATURE_MONITORING &&
				<Route
					path='/monitoring'
					component={MonitoringServiceRoutes}
				/>
			}
			{features.FEATURE_COMPANY_MONITORING &&
				<Route
					path='/companies/monitoring'
					component={CompanyMonitoringServiceRoutes}
				/>
			}
			<Route
				path='/logout'
				exact
				render={() => (
					<App
						header={<Header showLogoutButton={false} showMenu={false} />}
						content={<LogoutView />}
						heroHeadingContainer={<HeroHeadingContainer />}
					/>
				)}
			/>
			<Route
				render={({ location }) => (
					<CampaignRoute location={location} />
				)}
			/>
		</Switch>
	</DocumentTitle>
);

const RoutesWithTranslation = withTranslation()(Routes);

export default () => {
	return (
		<Suspense fallback=''>
			<RoutesWithTranslation />
		</Suspense>
	);
};
