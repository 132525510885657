import React, { useEffect, useState } from 'react';
import { Link, NavLink } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';
import cookies from 'browser-cookies';
import axios, { CancelToken } from 'axios';
import PropTypes from 'prop-types';

import { isAuthenticated } from '../../../auth';
import {
	getUserData,
	setUserLanguage,
} from '../../../fetcher/settings';

import { colors } from '../styles';
import * as logo from './DNBLogo.png'

const Header = ({ showLogoutButton = true, serviceId = '', serviceType, serviceName, setUserLanguage, getUserData }) => {

	const { t, i18n } = useTranslation(['miscellaneous']);
	const [showMenu, setshowMenu] = useState(false);
	const [selectedLanguage, setSelectedLanguage] = useState('fi');
	const [isLangDropdownOpen, setIsLangDropdownOpen] = useState(false);
	const [availableLanguages] = useState(['fi']);

	// English translation is outdated, disable the lang selection logic for now and always use 'fi'

	// useEffect(() => {
	// 	let cancelToken = CancelToken.source();

	// 	(async () => {
	// 		try {
	// 			// FIXME: getUserData returns 403 for admin user, throwing an error
	// 			isAuthenticated()
	// 				? setSelectedLanguage((await getUserData({ cancelToken: cancelToken.token })).languageCode || cookies.get('lang') || 'fi')
	// 				: setSelectedLanguage(cookies.get('lang') || 'fi');
	// 		} catch (error) {
	// 			if (axios.isCancel(error)) {
	// 				return;
	// 			}

	// 			throw error;
	// 		}

	// 		cancelToken = null;
	// 	})();

	// 	return () => cancelToken && cancelToken.cancel('Aborted on component unmount');
	// }, []); // eslint-disable-line

	useEffect(() => {
		(async () => {
			if (!selectedLanguage) return;

			i18n.changeLanguage(selectedLanguage);
			cookies.set('lang', selectedLanguage);
			if (isAuthenticated()) {
				await setUserLanguage({ languageCode: selectedLanguage });
			}
		})();
	}, [selectedLanguage]); // eslint-disable-line

	const MenuButtons = () =>
		<>
			<>
				<NavLink to='/data'
					href='#'
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
							icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--investigation'></span>
					<span className='icon-field__text'>{t('header.mydata')}</span>
				</NavLink>
				<NavLink to='/rights'
					href='#'
					activeClassName='navbar__menu-item--selected'
					style={styles.navLinkSeparator}
					className='navbar__menu-item
							icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--law'></span>
					<span className='icon-field__text'>{t('header.rights')}</span>
				</NavLink>
				<div
					className='navbar__menu-item u-display-none u-display-xl-inline-flex'
					style={styles.navLinkVerticalBar}
				/>
				<div
					className='navbar__menu-item u-display-xl-none u-display-block'
					style={styles.navLinkHorizontalBar}
				/>
				<NavLink to='/protection'
					href='#'
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
							icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon'>
						<svg xmlns="http://www.w3.org/2000/svg" style={{ height: '20px' }} viewBox='0 0 48 48'><path fill="#505569" stroke="#505569" d="M43.25 38.95 41 36.7V22.9H27.2l-6.45-6.45H41V11H15.3l-3-3H41q1.2 0 2.1.9.9.9.9 2.1v26q0 .55-.2 1.05t-.55.9ZM20.05 24.3Zm8.1-.45Zm-9.55-.95H7V37h25.7Zm22.05 22.05L35.7 40H7q-1.2 0-2.1-.9Q4 38.2 4 37V11q0-1.2.9-2.1Q5.8 8 7 8h1l3 3H7v5.45h5.15L1.3 5.6l2.15-2.15L42.8 42.8Z"/></svg>
					</span>
					<span className='icon-field__text'>{t('header.protection')}</span>
				</NavLink>
				<NavLink to='/monitoring'
					href='#'
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
							icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--person'></span>
					<span className='icon-field__text'>{t('header.peopleMonitoring')}</span>
				</NavLink>
				<NavLink to='/companies/monitoring'
					href='#'
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
							icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--company'></span>
					<span className='icon-field__text'>{t('header.companyMonitoring')}</span>
				</NavLink>
				{isAuthenticated() && <NavLink to='/settings'
					href='#'
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
					icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--settings'></span>
					<span className='icon-field__text'>{t('header.settings')}</span>
				</NavLink>}
			</>
			{showLogoutButton && !isAuthenticated() &&
				<NavLink to={`/${serviceId}/auth`}
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
							icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--log-out'></span>
					<span className='icon-field__text'>
						{t('header.login')}
					</span>
				</NavLink>}
			{showLogoutButton && isAuthenticated() &&
				<NavLink to='/logout'
					activeClassName='navbar__menu-item--selected'
					className='navbar__menu-item
						icon-field icon-field--vertical icon-field--vertical-lg-collapse header-element'
				>
					<span className='icon-field__icon icon icon--log-out'></span>
					<span className='icon-field__text'>
						{t('header.logout')}
					</span>
				</NavLink>
			}
		</>;

	const LanguageDropdown = () =>
		<div className='dropdown u-display-none'>
			<button className='u-text-upper dropdown__btn dropdown__btn--active' onClick={() => setIsLangDropdownOpen(state => !state)}>
				{selectedLanguage}
				<i className={classNames(
					'icon',
					isLangDropdownOpen ? 'icon--angle-up' : 'icon--angle-down',
					'btn__icon',
					'btn__icon--right')}
				></i>
			</button>
			<div className={classNames('dropdown__body',
				isLangDropdownOpen ? 'dropdown__body--open' : '')}
			>
				<ul className='dropdown-list'>
					{availableLanguages.map(language => {
						return <li
							key={language}
							className='u-text-upper dropdown-list__item'
							onClick={() => { setSelectedLanguage(language); setIsLangDropdownOpen(false); }}
						>{language}</li>;
					})}
				</ul>
			</div>
		</div>;

	return (
		<section className='navbar-container navbar-container--fixed'>
			<div className='navbar'>
				<div className='navbar__left'>
					<div className={classNames('col-auto', 'u-padding-default')}>
						<a href='/'>
							<img
								src={logo}
								alt='Dun & Bradstreet'
								style={styles.logo}
							/>
						</a>
					</div>

					<div
						style={styles.verticalBar}
						className={classNames('col-auto', 'u-margin-x-small', 'u-display-none', 'u-display-lg-block')}
					/>

					<div className={classNames('col-auto', 'u-margin-x-default', 'u-display-none', 'u-display-lg-block')}>
						<Link
							to={`/${serviceId}`}
							style={serviceType === 'companies' ? styles.omaDataCompanies : styles.omaData}
						>
							<h3 className='u-margin-none'>
								{serviceName ? serviceName : 'OmaData'}
							</h3>
						</Link>
					</div>
				</div>
				<nav className='navbar__right'>
					<div className='collapsable--lg-collapse'>
						<div className='navbar__menu collapsable__inner'>
							<MenuButtons />
						</div>
					</div>
					{/* <span className='collapsable--lg-collapse'>
						<LanguageDropdown />
					</span> */}
					<div className='collapsable collapsable--lg-collapse collapsable--open'>
						<div className='u-display-xl-none navbar__menu collapsable__inner'>
							{showMenu && <MenuButtons />}
						</div>
					</div>
				</nav>
				<nav className='navbar__right u-display-xl-none'>
					<span
						className='navbar__menu-item icon-field icon-field--vertical'
					>
						<LanguageDropdown />
					</span>
					<span
						className='navbar__menu-item icon-field icon-field--vertical'
						onClick={() => setshowMenu(state => !state)}
					>
						<span className='icon-field__icon icon icon--menu'></span>
						<span className='icon-field__text'>{t('header.menu')}</span>
					</span>
				</nav>
			</div>
		</section >
	);
};

const styles = {
	wrapper: {
		backgroundColor: colors.white,
	},
	companiesWrapper: {
		backgroundColor: colors.white,
	},
	content: {
		height: '7rem',
	},
	logo: {
		userSelect: 'none',
		height: '1.75rem',
		marginTop: '-.25rem',
	},
	verticalBar: {
		borderStyle: 'solid',
		borderColor: colors.bisGreySilver,
		borderWidth: 0.5,
		height: '2.5rem',
	},
	navLinkVerticalBar: {
		width: '0',
		borderStyle: 'solid',
		borderColor: colors.bisGreySilver,
		borderWidth: '0 1px 0 0',
		margin: '0 1.5rem',
		padding: '0',
		height: '2.5rem',
		cursor: 'default',
	},
	navLinkHorizontalBar: {
		width: '100%',
		height: '0',
		borderStyle: 'solid',
		borderColor: colors.bisGreySilver,
		borderWidth: '1px 0 0 0',
		margin: '0 0',
		padding: '0',
		cursor: 'default',
	},
	omaData: {
		color: colors.black,
		textDecoration: 'none',
	},
	omaDataCompanies: {
		color: colors.black,
		textDecoration: 'none',
	},
};

Header.propTypes = {
	showLogoutButton: PropTypes.bool,
	serviceId: PropTypes.string,
	serviceName: PropTypes.string,
	serviceType: PropTypes.string,
	getUserData: PropTypes.func,
	setUserLanguage: PropTypes.func,
};

export default connect(
	undefined,
	dispatch => bindActionCreators({
		getUserData,
		setUserLanguage,
	}, dispatch),
)(Header);
