import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class AlertBlock extends PureComponent {

	static propTypes = {
		children: PropTypes.node,
		onClose: PropTypes.func,
		type: PropTypes.oneOf([
			'info',
			'success',
			'warning',
			'danger',
		]),
		className: PropTypes.string,
	};

	static defaultProps = {
		type: 'danger',
	};


	render() {
		const {
			children,
			onClose,
			type,
			className,
			...passProps
		} = this.props;

		const classNameFull = classNames(
			'alert-container',
			`alert-container--${type}`,
			className,
		);

		return (
			<div className={classNameFull} name="alert-block" {...passProps}>
				<div className='alert'>
					<div
						className={classNames(
							'alert__icon',
							`alert__icon--${type}`,
						)}
					/>
					<div
						className={classNames({
							alert__text: onClose, // If a close handler was provided, this makes room for the close icon.
						})}
					>
						{children}
					</div>
					{onClose &&
						<button className='alert__close' onClick={onClose} />
					}
				</div>
			</div>
		);
	}

}
