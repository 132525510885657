import React, { PureComponent } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';


export default class Spinner extends PureComponent {

	static propTypes = {
		color: PropTypes.oneOf([ 'white' ]),
		className: PropTypes.any,
	};


	render() {
		return (
			<div className={classNames('spinner-container', this.props.className)}>
				<svg className='spinner' viewBox='0 0 64 64' xmlns='http://www.w3.org/2000/svg'>
					<g
						fillRule='evenodd'
						className={classNames('spinner__dots', this.props.color)}
					>
						<path d='M27.337 4.398c0-2.235 1.812-4.045 4.046-4.045 2.235 0 4.046 1.81 4.046 4.045s-1.812 4.046-4.047 4.046c-2.234 0-4.046-1.81-4.046-4.046' />
						<path d='M27.337 59.105c0-2.234 1.812-4.045 4.046-4.045 2.235 0 4.046 1.81 4.046 4.045s-1.812 4.046-4.047 4.046c-2.234 0-4.046-1.81-4.046-4.045' />
						<path d='M54.693 31.752c0-2.235 1.812-4.046 4.047-4.046 2.235 0 4.045 1.81 4.045 4.046 0 2.234-1.81 4.046-4.045 4.046s-4.047-1.812-4.047-4.046' />
						<path d='M-.02 31.752c0-2.235 1.813-4.046 4.047-4.046 2.235 0 4.046 1.81 4.046 4.046 0 2.234-1.81 4.046-4.046 4.046-2.234 0-4.046-1.812-4.046-4.046' />
						<path d='M9.21 9.932c1.58-1.58 4.142-1.58 5.72 0 1.582 1.58 1.582 4.142.002 5.722s-4.142 1.58-5.722 0-1.58-4.142 0-5.722' />
						<path d='M47.894 48.616c1.58-1.58 4.14-1.58 5.72 0 1.582 1.58 1.582 4.142 0 5.722-1.58 1.58-4.14 1.58-5.72 0s-1.58-4.142 0-5.722' />
						<path d='M47.895 9.93c1.58-1.58 4.142-1.58 5.722 0s1.58 4.142 0 5.722-4.14 1.58-5.72 0-1.582-4.142-.002-5.722' />
						<path d='M9.208 48.618c1.58-1.58 4.142-1.58 5.722 0s1.58 4.142 0 5.722-4.142 1.58-5.722 0-1.58-4.142 0-5.722' />
					</g>
					<path
						className={classNames('spinner__letter', this.props.color)}
						d='M27.273 37.616h5.6c1.395 0 2.81-.27 2.81-2.186 0-1.374-.957-2.102-2.352-2.102h-6.057v4.288zm0-6.496h5.83c.873 0 2.018-.208 2.018-1.894 0-1.228-.748-1.81-2.56-1.81h-5.287v3.704zm-3.123-6.037h9.826c3.394 0 4.268 1.81 4.268 3.706 0 2.122-1.228 2.726-2.02 3.1 1.146.355 2.707 1.187 2.707 3.686 0 1.665-1.04 4.372-5.058 4.372H24.15V25.083z'
					/>
				</svg>
			</div>
		);
	}

}
