import boolean from 'boolean';


const features = {
	FEATURE_MONITORING: boolean(process.env.REACT_APP_FEATURE_MONITORING),
	FEATURE_MONITORING_CHANGES_SCENE: boolean(process.env.REACT_APP_FEATURE_MONITORING_CHANGES_SCENE),
	FEATURE_PROTECTION_FREE_FOR_MONITORING_CONSUMERS: boolean(process.env.REACT_APP_FEATURE_PROTECTION_FREE_FOR_MONITORING_CONSUMERS),
	FEATURE_MONITORING_PAYMENT: boolean(process.env.REACT_APP_FEATURE_MONITORING_PAYMENT),
	FEATURE_COMPANY_MONITORING: boolean(process.env.REACT_APP_FEATURE_MONITORING_COMPANY),
};

export default features;
