import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class HeroHeadingContainer extends PureComponent {

	static propTypes = {
		backgroundImagePath: PropTypes.string,
		heroHeading: PropTypes.node,
	};

	render() {
		const fullWrapperStyle = {
			...styles.wrapper,
			...(this.props.heroHeading ? styles.wrapperWithText : styles.wrapperNoText),
			...(this.props.backgroundImagePath && { backgroundImage: `url(${this.props.backgroundImagePath})` }),
		};

		return (
			<section
				style={fullWrapperStyle}
				className={classNames(
					'section', 'section--tight', 'u-bg-faded',
				)}
			>
				<div
					className={classNames(
						'u-margin-bottom-default',
					)}
				>
					<div className='col'>
						{this.props.heroHeading}
					</div>
				</div>
			</section>
		);
	}

}

const styles = {
	wrapper: {
		backgroundSize: 'cover',
		backgroundRepeat: 'no-repeat',
	},
	wrapperNoText: {
		paddingBottom: '21rem',
		marginBottom: '-21rem',
	},
	wrapperWithText: {
		marginLeft: '-.5rem',
		marginRight: '-.5rem',
	},
};
