import { fetchApiJson } from '.';

/**
 * Get user data.
 *
 * @param {object} options
 *
 * @param {object} options
 */
export const getUserData = (options) => async dispatch => {
	return await dispatch(fetchApiJson('consumer/personsettings', {
		method: 'get',
		...options,
	}));
};

/**
 * Set user data.
 *
 * @param {object} options
 *
 */
export const setUserData = ({ email, languageCode, name, agreedToReceiveAds, agreedToTransferData, phoneNumber } = null, options) => async dispatch => {
	await dispatch(fetchApiJson('consumer/personsettings', {
		method: 'post',
		data: {
			name,
			languageCode,
			email,
			phoneNumber,
			agreedToReceiveAds,
			agreedToTransferData,
		},
		...options,
	}));
};

/**
 * Set user language.
 *
 * @param {object} options
 *
 */
export const setUserLanguage = ({ languageCode }, options) => async dispatch => {
	await dispatch(fetchApiJson('consumer/personsettings/language', {
		method: 'post',
		data: {
			languageCode,
		},
		...options,
	}));
};
