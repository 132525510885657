import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import cookies from 'browser-cookies';
import ReactRouterPropTypes from 'react-router-prop-types';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { Redirect } from 'react-router-dom';

import { getCampaign } from '../fetcher/payment';
import Spinner from './common/Spinner';
import { isValidCampaign } from './common/util';

const CampaignRoute = ({ getCampaign, location }) => {

	CampaignRoute.propTypes = {
		getCampaign: PropTypes.func,
		children: PropTypes.node,
		location: ReactRouterPropTypes.location.isRequired,
	};

	const [campaign, setCampaign] = useState();
	const code = location.pathname.slice(1);

	useEffect(() => {
		(async () => {
			const campaign = await getCampaign(code);
			setCampaign(campaign);
			if (isValidCampaign(campaign)) {
				cookies.set('campaign', code);
			} else {
				cookies.erase('campaign');
			}
		})();
	}, [code, getCampaign]);

	const campaignUrls = {
		'companiesMonitoring': '/companies/monitoring',
		'monitoring': '/monitoring',
		'protection': '/protection',
	};

	const campaignTargetUrl = campaign && campaignUrls[campaign.serviceId];
	return (
		campaign === undefined
			? <Spinner />
			: campaign !== null && campaignTargetUrl
				? <Redirect to={campaignTargetUrl} />
				: <Redirect to={'/'} /> // campaign does not exist
	);
}

export default connect(
	undefined,
	dispatch => bindActionCreators({
		getCampaign,
	}, dispatch),
)(CampaignRoute);
