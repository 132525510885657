import moment from 'moment';

/**
 * Get the error message displayed to user based on the error code.
 *
 * @param {string} errorCode The error code returned by server
 *
 * @param {object[]} errorMessages Contains error messages in plain language,
 *        that correspond to the error code
 *
 * @param {string} defaultMessage The message that is displayed if
 *        there's no specific error message for the error code
 *
 * @return {string}
 */
export function getErrorMessage(errorCode, errorMessages, defaultMessage) {
	// Get the message for the error code. If a message is not found for the
	// error code, use the default message.
	const errorMessage = errorMessages[errorCode] || defaultMessage;

	return errorMessage;
}

/**
 * Censor the last part of a social security number.
 *
 * @param {string} socialSecurityNumber
 *
 * @return {string}
 */
export function getCensoredSocialSecurityNumber(socialSecurityNumber) {
	const censoredSocialSecurityNumber = socialSecurityNumber.slice(0, -4) + 'XXXX';

	return censoredSocialSecurityNumber;
}

// Convert date to string so it can be rendered in an undestandable form
// Also convert to finnish format
export const convertDateToString = (remarkDate) => {
	const typeToDate = new Date(remarkDate);

	const remarkDateDay = typeToDate.getDate();
	const remarkDateMonth = typeToDate.getMonth() + 1;
	const remarkDateYear = typeToDate.getFullYear();

	return `${remarkDateDay <= 9 ? 0 : ''}${remarkDateDay}.${remarkDateMonth <= 9 ? 0 : ''}${remarkDateMonth}.${remarkDateYear}`;
};

export const compareDates = (a, b) => {
	const c = new Date(a.date);
	const d = new Date(b.date);
	return c.getTime() - d.getTime();
};

export const compareEvents = (a, b) => {
	return (`${a.event}`).localeCompare(b.event);
};

export const compareTargets = (a, b) => {
	return (`${a.target}`).localeCompare(b.target);
};

export const compareTypes = (a, b) => {
	return (`${a.type}`).localeCompare(b.type);
};

export const compareSources = (a, b) => {
	return (`${a.type}`).localeCompare(b.type);
};

// Check if a single change has happened in the last 24 hours
export const changeHappenedIn24hours = (change) => {
	var check = moment(change[0], 'DD/MM/YYYY');
	return moment().diff(check, 'days') < 1
	// return moment().diff(change.changedAt, 'hours') <= 24;
};

// Check if a single change has happened in the last 72 hours
export const changeHappenedIn72Hours = (change) => {
	return moment().diff(change.changedAt, 'hours') <= 72;
};

// Check if changes have happened in the last 24 hours
export const changesHappenedIn24hours = (changes) => {
	return changes.some(x => moment().diff(x.changedAt, 'hours') <= 24);
};

// Check if changes have happened in the last 72 hours
export const changesHappenedIn72hours = (changes) => {
	return changes.some(x => moment().diff(x.changedAt, 'hours') <= 72);
};

// Check if there are 1 or 2 changes in the last 90 days
export const changes1or2In90Days = (changes) => {
	const count = changes.filter(x => moment().diff(x.changedAt, 'days') <= 90);
	return count.length === 1 || count.length === 2;
};

// Check if there are more than 3 changes in the last 90 days
export const changes3orMoreIn90Days = (changes) => {
	const count = changes.filter(x => moment().diff(x.changedAt, 'days') <= 90);
	return count.length >= 3;
};

// Check if there are more than 12 changes in the last 360 days
export const changes12orMoreIn360Days = (changes) => {
	const count = changes.filter(x => moment().diff(x.changedAt, 'days') <= 360);
	return count.length >= 12;
};

// Changes happened in the last 360 days
export const changesHappenedIn360Days = (changes) => {
	const newChanges = changes.filter(x => moment().diff(x.changedAt, 'days') <= 360);
	return newChanges;
};

// Check if string is email
const emailRe = new RegExp(/^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
export const isEmailFormValid = (email) => {
	return emailRe.test(email);
};

const ssnRe = new RegExp(/^[\d]{6}[a+-][\d]{3}[\da-z]$/i)
export const isSsnFormValid = (ssn) => {
	return ssnRe.test(ssn);
};

export const isValidCampaign = (campaign, serviceId=undefined) => {
	const now = new Date();
	return campaign &&
		// serviceId is optional, ignore if not given
		(! serviceId || campaign.serviceId.includes(serviceId)) &&
		Date.parse(campaign.validSince) <= now && Date.parse(campaign.validUntil) > now;
}

// Backend may return "Invalid date" instead of null
export const formatDate = dateString =>
	dateString && dateString !== 'Invalid date'
		? moment(dateString).format('DD.MM.YYYY')
		: null
