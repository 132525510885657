export const colors = {
	blue: '#3E8CD5',
	bisBlue: '#348AE0',
	green: '#449e79',
	yellow: '#ffb851',
	red: '#da3b3b',
	bisRed: '#E46E4A',

	grey: '#F2F2F2',
	lightGrey: '#FAFAFA',
	bisGreySilver: '#C4C4C4',
	bisGreyAlto: '#dddddd',
	beige: '#F9F7F4',
	darkGrey: '#7D7D7D',
	blackGraphite: '#292929',
	black: '#000000',
	white: '#FFFFFF',
	hoverBlue: '#48A0F2',
	uBgFaded: '.u-bg-faded',
};
