import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import Radium from 'radium';

import { colors } from './styles';


class ContentBlock extends PureComponent {

	static propTypes = {
		className: PropTypes.string,
		style: PropTypes.object,
		children: PropTypes.node,
	};

	render() {
		const {
			className,
			style,
			...passProps
		} = this.props;

		const classNameFull = classNames(
			'card',
			'section--tight',
			'u-padding-large',
			className,
		);

		return (
			<div style={[ styles.wrapper, style ]} className={classNameFull} {...passProps}>
				{this.props.children}
			</div>
		);
	}

}

const styles = {
	wrapper: {
		backgroundColor: colors.white,
	},
};

export default Radium(ContentBlock);
