import PropTypes from 'prop-types';
import React, { PureComponent } from 'react';

import Footer from './common/Footer';



export default class App extends PureComponent {

	static propTypes = {
		header: PropTypes.node.isRequired,
		content: PropTypes.node.isRequired,
		heroHeadingContainer: PropTypes.node,
		consentAlert: PropTypes.node,
	};


	render() {
		return (
			<div className="wrapper">
				<div>
					{this.props.consentAlert}
					{this.props.header}
					{this.props.heroHeadingContainer}
					<div>
						{this.props.content}
					</div>
				</div>
				<div>
					<Footer />
				</div>
			</div>
		);
	}

}

// if (window.google_tag_manager && process.env.NODE_ENV === 'production')
// 	history.listen((location) => {
// 		window.gtag('config', 'UA-37185334-13', {
// 			page_location: window.location.href,
// 			page_path: location.pathname,
// 		});
// 	});
