import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class CommonText extends PureComponent {

	static propTypes = {
		children: PropTypes.node,
		className: PropTypes.string,
		style: PropTypes.object,
	};

	render() {
		const {
			children,
			className,
			style,
		} = this.props;

		const classNameFull = classNames(
			'u-text-light',
			'font-size-5',
			className,
		);

		return (
			<p
				className={classNameFull}
				style={style}
			>
				{children}
			</p>
		);
	}

}
