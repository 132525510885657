import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import Arrow from './Arrow';


export default class ArrowButton extends PureComponent {

	static propTypes = {
		to: PropTypes.string,
		children: PropTypes.string,
		secondary: PropTypes.bool,
		onClick: PropTypes.func,
		omitArrowIcon: PropTypes.bool,
		disabled: PropTypes.bool,
		className: PropTypes.string,
	};


	render() {
		const {
			children,
			omitArrowIcon,
			className,
			secondary,
			...passProps
		} = this.props;

		const ButtonComponent = passProps.to ? Link : 'button';

		return (
			<ButtonComponent
				style={styles.button}
				className={classNames(
					'btn',
					secondary ? 'btn--secondary' : 'btn--primary',
					'btn--large',
					'paragraph-condensed',
					'u-font-size-6',
					className,
				)}
				{...passProps}
			>
				{children}
				{!omitArrowIcon &&
					<Arrow
						className='u-margin-left-medium'
						style={styles.arrow}
						disabled={passProps.disabled}
					/>
				}
			</ButtonComponent>
		);
	}

}

const styles = {
	button: {
		lineHeight: '1.3rem',
		minWidth: '10.5rem',
	},
	arrow: {
		height: '1.4rem',
		width: '1.4rem',

		marginBottom: '-0.3rem',
	},
};
