import cookies from 'browser-cookies';


/**
 * Checks if user is authenticated.
 *
 * @return {bool}
 */
export function isAuthenticated() {
	const isAuthenticated = Boolean(cookies.get('csrf'));

	return isAuthenticated;
}
