import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class SectionHeader extends PureComponent {

	static propTypes = {
		children: PropTypes.node,
		className: PropTypes.string,
		isPrimaryText: PropTypes.bool,
	};

	static defaultProps = {
		isPrimaryText: true,
	};

	render() {
		const {
			className,
			children,
			isPrimaryText,
		} = this.props;

		const classNameFull = classNames(
			{ 'u-text-primary': isPrimaryText },
			'u-font-size-4',
			'u-text-light',
			className,
		);

		return (
			<h3 className={classNameFull}>
				{children}
			</h3>
		);
	}

}
