import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';


export default class SectionRow extends PureComponent {

	static propTypes = {
		className: PropTypes.string,
		children: PropTypes.node,
	};


	render() {
		const {
			className,
			children,
		} = this.props;

		const classNameFull = classNames(
			'row',
			'u-margin-x-md-default',
			className,
		);

		return (
			<div className={classNameFull}>
				{children}
			</div>
		);
	}

}
