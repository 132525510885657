import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { Router } from 'react-router-dom';
import { persistStore, autoRehydrate } from 'redux-persist-immutable';
import { FeatureProvider } from 'react-feature-gate';
import 'react-app-polyfill/ie11';

import { createAppStore, populateTestData } from './store';
import history from './history';
import Routes from './components/Routes';
import features from './features';

// import i18n (needs to be bundled ;))
import './i18n';

import './styles/index.css';
import './styles/mobile.css';

const store = createAppStore([ autoRehydrate() ]);

const whitelist = [];
if (process.env.REACT_APP_STORAGE_WHITELIST) {
	// Defining REACT_APP_STORAGE_WHITELIST in you .env.local is a good
	// way to ease local development by preserving state between reloads
	// caused by source code changes.
	// Add a comma (,) separated list of reducer names to sync them into
	// local storage.
	//
	// Example:
	//     REACT_APP_STORAGE_WHITELIST=user,consumerDataReport

	const whitelistedReducers = process.env.REACT_APP_STORAGE_WHITELIST.split(',');
	whitelist.push(...whitelistedReducers);
}

if (process.env.REACT_APP_POPULATE_TEST_DATA) {
	populateTestData(store.dispatch);
}

persistStore(store, { whitelist }, () => {
	ReactDOM.render(
		<Provider store={store}>
			<FeatureProvider features={features}>
				<Router history={history}>
					<Routes />
				</Router>
			</FeatureProvider>
		</Provider>,
		document.getElementById('root'),
	);
});
