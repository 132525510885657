import { createStore, applyMiddleware, compose } from 'redux';
import thunk from 'redux-thunk';
import { change } from 'redux-form';

import rootReducer from './rootReducer';


export function createAppStore(enchancers = []) {
	let composeEnhancers = compose;
	if (typeof window !== 'undefined') {
		// When running on browser, enable Redux DevTools if the
		// extension is enabled.
		composeEnhancers = window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__ || compose;
	}

	const store = createStore(
		rootReducer,
		composeEnhancers(
			applyMiddleware(thunk),
			...enchancers,
		),
	);

	return store;
}

export function populateTestData(dispatch) {
	dispatch(change('login', 'username', 'testuser'));
	dispatch(change('login', 'password', 'supersecret'));

	dispatch(change('adminDataReport', 'socialSecurityNumber', '160162-9968'));
	dispatch(change('adminDataReport', 'purposeCode', '6'));
	dispatch(change('adminDataReport', 'firstName', 'Kanerva'));
	dispatch(change('adminDataReport', 'lastName', 'Tuulispää'));
	dispatch(change('adminDataReport', 'streetAddress', 'Kumpulantie 3'));
	dispatch(change('adminDataReport', 'postalCode', '00520'));
	dispatch(change('adminDataReport', 'postOffice', 'Helsinki'));

	dispatch(change('adminRightsUserInfo', 'socialSecurityNumber', '160162-9968'));
	dispatch(change('adminRightsUserInfo', 'purposeCode', '6'));
	dispatch(change('adminRightsUserInfo', 'firstName', 'Kanerva'));
	dispatch(change('adminRightsUserInfo', 'lastName', 'Tuulispää'));
	dispatch(change('adminRightsUserInfo', 'streetAddress', 'Kumpulantie 3'));
	dispatch(change('adminRightsUserInfo', 'postalCode', '00520'));
	dispatch(change('adminRightsUserInfo', 'postOffice', 'Helsinki'));
}
