import { combineReducers } from 'redux-immutablejs';
import { reducer as form } from 'redux-form/immutable';

import { stateClear } from './actions';


const nestedReducers = combineReducers({
	form,
});

export default function rootReducer(state, action) {
	if (action.type === stateClear.toString()) {
		// Calling reducers with state set to `undefined` will reset
		// them to the initial state.
		state = undefined;
	}

	state = nestedReducers(state, action);

	return state;
}
