import queryString from 'query-string';
import cookies from 'browser-cookies';

import { fetchApiJson } from '.';
import { isAuthenticated } from '../auth';
import { getUserInfo } from './auth';


/**
 * Generate a new Paytrail payment.
 *
 * @param {string} .productType
 * @param {object} [.additionalData]
 *
 * @param {object} options
 *
 * @return {object}
 */
export const generatePayment = ({ productType, additionalData }, options) => async dispatch => {
	const payment = await dispatch(fetchApiJson('payment', {
		method: 'post',
		data: {
			productType,
			additionalData,
			campaignCode: cookies.get('campaign'),
		},
		...options,
	}));

	return payment;
};

/**
 * Get a Paytrail payment.
 *
 * @param {string} orderNumber
 *
 * @param {object} options
 *
 * @return {object}
 */
export const getPayment = ({ orderNumber, paymentStatus }, options) => async dispatch => {
	const qs = queryString.stringify({ paymentStatus });

	const payment = await dispatch(fetchApiJson(`payment/${orderNumber}?${qs}`, {
		method: 'get',
		...options,
	}));

	return payment;
};


export const getPrices = (options) => async dispatch => {
	let payment = null;
	const campaignCode = cookies.get('campaign');
	const params = campaignCode ? { campaignCode } : undefined;

	if (isAuthenticated()) {
		payment = await dispatch(fetchApiJson(`payment/prices/personal`, {
			method: 'get',
			params,
			...options,
		}));
	}
	else {
		payment = await dispatch(fetchApiJson(`payment/prices`, {
			method: 'get',
			params,
			...options,
		}));
	}
	return payment;
};

export const getCampaign = (campaignCode, options) => async dispatch => {
	let campaign = null;
	try {
		if (campaignCode) {
			campaign = await dispatch(fetchApiJson(`payment/campaign/${campaignCode}`, {
				method: 'get',
				...options,
			}));
		}
	} catch (error) {
		if (error.response.status === 404) {
			cookies.erase('campaign');
		}
	}
	return campaign;
};
