import axios from 'axios';
import cookies from 'browser-cookies';
import PromiseFileReader from 'promise-file-reader';
import queryString from 'query-string';
import { stopSubmit } from 'redux-form/immutable';

import history from '../history';
import { destroySessionAndState } from './auth';




/**
 * Execute an API call to the Node.js server.
 *
 * @param {string} path
 * @param {object} options Options forwarded to `axios(..)`
 * @param {string} [options.unauthorizedErrorMessage='expired'] The value for `error` query param
 *        for unauthorized requests that get redirected to authenticated view.
 * @param {boolean} [options.redirectToAuthOnExpiredSession=true] If the session has expired, i.e.
 *        a response with 401 status code is returned, whether to redirect to authentication scene.
 *
 * @throws {Error} If an error (with status code other than 401) occurred when making the HTTP request.
 */
export const fetchApi = (path, options = {}) => async dispatch => {
	const currentService = cookies.get('service');

	const {
		unauthorizedErrorMessage = 'expired',
		redirectToAuthOnExpiredSession = true,
		...axiosOptions
	} = options;

	try {
		const pathPrefix = path.startsWith('/') ? '' : '/api/v1/';
		const response = await axios(`${pathPrefix}${path}`, {
			xsrfCookieName: 'csrf',
			xsrfHeaderName: 'x-csrf-token',
			credentials: 'same-origin',
			...axiosOptions,
		});

		return response;
	}
	catch (error) {
		if (error.response && error.response.status === 401) {
			if (redirectToAuthOnExpiredSession) {
				// Destroy session cookies and Redux state.
				dispatch(destroySessionAndState());

				dispatch(stopSubmit('login', {
					_error: 'Istunto on vanhentunut.', // TODO Translate
				}));

				// If the current service is set in the 'service' cookie, redirect to the
				// authentication page of that service. Otherwise, redirect to the front page.
				if (currentService) {
					const authQueryString = queryString.stringify({
						error: unauthorizedErrorMessage,
						redirectPath: getEncodedRedirectPath(),
					});

					history.push(`/${currentService}/auth?${authQueryString}`);
				}
				else {
					history.push('/');
				}

				throw Error('You are not authorized to access this page');
			}

			return;
		}

		if (error.response && error.response.data) {
			// Add error code from the response body directly to the error object.
			error.errorCode = error.response.data.errorCode;

			// If blob was specified as responseType, error data is also returned as blob,
			// and needs to be parsed into object.
			if (error.response.config.responseType === 'blob') {
				const errorString = await PromiseFileReader.readAsText(error.response.data);
				const { errorCode } = JSON.parse(errorString);

				error.errorCode = errorCode;
			}
		}

		// User cancelled the request.
		if (axios.isCancel(error)) {
			throw error;
		}
		else {
			// Print errors to console if logging fetch errors is enabled.
			if (process.env.REACT_APP_LOG_FETCH_ERRORS) {
				console.error(error); // eslint-disable-line no-console
			}

			if (error.response && error.response.status >= 500) {
				// TODO: translate and/or get this message from a common place.
				throw Error('Palvelussa tapahtui virhe. Odota hetki, ja yritä uudelleen.');
			}

			throw error;
		}
	}

};

/**
 * Execute an API call to the Node.js server and parse the JSON response.
 *
 * @param {string} path
 * @param {object} options Options forwarded to `axios(..)`
 *
 * @throws {Error} If an error (with status code other than 401) occurred when making the HTTP request.
 */
export const fetchApiJson = (path, options = {}) => async dispatch => {
	options.headers = {
		Accept: 'application/json',
		'Content-Type': 'application/json',
		...options.headers,
	};

	const response = await dispatch(fetchApi(path, options));

	if (!response || response.status === 204) {
		// No content was returned.
		return;
	}

	return response.data;
};

/**
 * Get the URL encoded redirect path for the current location.
 *
 * @return {string}
 */
export function getEncodedRedirectPath() {
	const redirectPath = encodeURIComponent(window.location.pathname + window.location.search);

	return redirectPath;
}
