import React from 'react';
import { useTranslation } from 'react-i18next';
import classNames from 'classnames';

import { colors } from '../styles';

const Footer = () => {

	const { t } = useTranslation(['miscellaneous']);

	return (
		<footer
			style={styles.wrapper}
			className={classNames('u-display-flex', 'u-align-items-center')}
		>
			<div className={classNames('container', 'caption')}>
				<div
					className={classNames(
						'row',
						'justify-content-center',
						'u-margin-bottom-small',
					)}
				>
					<div style={styles.serviceProvider} className='col-auto'>
						<strong>{t('footer.header')}</strong>
					</div>
				</div>

				<div
					className={classNames(
						'row',
						'u-justify-content-center',
						'align-items-center',
						'u-margin-bottom-small',
					)}
				>
					<div className='col-auto'>
						<a href='https://finland.bisnode.fi/'>
							<strong>Dun & Bradstreet Finland Oy</strong>
						</a>
					</div>
					<div
						style={styles.verticalBar}
						className={classNames('col-auto', 'u-margin-x-default')}
					/>
					<div className='col-auto'>
						<a href='mailto:asiakaspalvelu@dnb.com'>
							<strong>asiakaspalvelu@dnb.com</strong>
						</a>
					</div>
					<div
						style={styles.verticalBar}
						className={classNames('col-auto', 'u-margin-x-default')}
					/>
					<div className='col-auto'>
						<a href='tel:+3589272702333'>
							<strong>+358 (0)9 2727 02333</strong>
						</a>
					</div>
				</div>
				<div className={classNames(
					'row',
					'u-justify-content-center',
					'align-items-center',
				)}
				>
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='https://www.dnb.com/fi-fi/omadata/omadata-palveluiden-kayttoehdot'
					>
						{t('footer.termsOfUse')}
					</a>

					<a
						className="u-margin-x-large"
						target='_blank'
						rel='noopener noreferrer'
						href='https://www.dnb.com/fi-fi/tietosuoja.html'
					>
						{t('footer.privacyStatement')}
					</a>
					<a
						target='_blank'
						rel='noopener noreferrer'
						href='https://finland.bisnode.fi/evasteet/'
					>
						{t('footer.cookiePolicy')}
					</a>
				</div>
			</div>
		</footer>
	);
};

const styles = {
	wrapper: {
		backgroundColor: colors.grey,

		height: '8rem',
	},
	serviceProvider: {
		color: colors.blackGraphite,
	},
	verticalBar: {
		borderStyle: 'solid',
		borderColor: colors.darkGrey,
		borderWidth: 0.5,

		height: '1.9rem',
	},
};

export default Footer;
