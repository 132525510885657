import cookies from 'browser-cookies';

import { fetchApiJson } from './';
import { stateClear } from '../store/actions';


/**
 * Destroys the session (removes cookies) and the Redux state.
 */
export const destroySessionAndState = () => async dispatch => {
	// Erase the CSRF token cookie.
	cookies.erase('csrf');
	cookies.erase('service');

	// Clear everything from the Redux state.
	dispatch(stateClear());
};

/**
 * Make a login API call.
 *
 * @param {string} username
 * @param {string} password
 * @param {object} options
 *
 * @return {object} The logged in user.
 *
 * @throws {Error} If a login error (such as bad credentials) occurs.
 */
export const loginWithUserName = (username, password, options) => async dispatch => {
	const user = await dispatch(fetchApiJson('auth/login', {
		method: 'post',
		data: {
			username,
			password,
		},
		...options,
	}));

	return user;
};

/**
 * Make a logout API call.
 *
 * @return {object} The logged in user.
 *
 * @throws {Error} If a logout error (such as an invalid session ID) occurs.
 */
export const logout = (options) => async dispatch => {
	await dispatch(fetchApiJson('auth/logout', {
		method: 'post',
		redirectToAuthOnExpiredSession: false,
		...options,
	}));

	// Destroy session cookies and Redux state.
	dispatch(destroySessionAndState());
};

/**
 * Get user info.
 */
export const getUserInfo = (options) => async dispatch => {
	const userInfo = await dispatch(fetchApiJson('auth/user/info', {
		method: 'get',
		...options,
	}));

	return userInfo;
};
