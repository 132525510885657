import React, { useState, useEffect } from 'react';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import axios, { CancelToken } from 'axios';
import cookies from 'browser-cookies';

import { logout } from '../../../fetcher/auth';

import ContentBlock from '../ContentBlock';
import Spinner from '../Spinner';
import SectionHeader from '../SectionHeader';
import SectionRow from '../SectionRow';
import CommonText from '../CommonText';
import ArrowButton from '../ArrowButton';
import AlertBlock from '../AlertBlock';

import { getErrorMessage } from '../util';

const LogoutView = ({ logout }) => {

	LogoutView.propTypes = {
		logout: PropTypes.func,
	};

	LogoutView.defaultProps = {
		logout: () => {},
	};

	const { t } = useTranslation(['miscellaneous']);

	const [cancelLogout, setCancelLogout] = useState(CancelToken.source());
	const [logoutError, setLogoutError] = useState(null);
	const [isLoggingOut, setIsLoggingOut] = useState(true);
	const [currentService, setCurrentService] = useState('/');

	useEffect (() => {
		(async () => {
			try {
				const currentService = cookies.get('service') ? cookies.get('service') : '/';

				setCancelLogout(cancelLogout)
				setCurrentService(currentService)

				// When this component gets mounted, we log the user out immediately.
				await logout({ cancelToken: cancelLogout.token });
			}
			catch (error) {
				if (axios.isCancel(error)) {
					return;
				}

				const errorMessages = {};
				const defaultErrorMessage = t('errors.logOutError');
				const errorMessage = getErrorMessage(error.errorCode, errorMessages, defaultErrorMessage);

				setLogoutError(errorMessage);
			}

			setIsLoggingOut(false)
			setCancelLogout(null)
		})();
		return () => {
			if (cancelLogout) {
				cancelLogout.cancel('Aborted on component unmount');
			}
		};
	}, []); // eslint-disable-line

	let content;
	if (isLoggingOut) {
		content = (
			<SectionRow>
				<div className={classNames('col-12', 'u-margin-default')}>
					<h3 className='u-text-center'>
						{t('logOut.isLoggingOut')}
					</h3>
				</div>
				<div className={classNames('col-12', 'u-margin-bottom-large')}>
					<Spinner />
				</div>
			</SectionRow>
		);
	}
	else if (logoutError) {
		content = (
			<AlertBlock className='u-margin-default'>
				{logoutError}
			</AlertBlock>
		);
	}
	else {
		content = (
			<>
				<SectionRow>
					<div className='col'>
						<SectionHeader className='u-text-center'>
							{t('logOut.header')}
						</SectionHeader>
						<CommonText className='u-text-center'>
							{t('logOut.text')}
						</CommonText>
					</div>
				</SectionRow>

				<SectionRow className='u-justify-content-center'>
					<div className='u-align-self-center'>
						<ArrowButton
							to={currentService}
							omitArrowIcon={true}
						>
							{t('logOut.button')}
						</ArrowButton>
					</div>
				</SectionRow>
			</>
		);
	}

	return (
		<ContentBlock className='container'>
			{content}
		</ContentBlock>
	);
}

export default connect(
	undefined,
	dispatch => bindActionCreators({
		logout,
	}, dispatch),
)(LogoutView);
