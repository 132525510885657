import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';

import { colors } from './styles';


export default class Arrow extends PureComponent {


	static propTypes = {
		className: PropTypes.string,
		style: PropTypes.object,
		disabled: PropTypes.bool,
	}

	render() {
		const {
			className,
			style,
			disabled,
		} = this.props;

		return (
			<svg
				viewBox='0 0 26 23'
				xmlns='http://www.w3.org/2000/svg'
				className={className}
				style={style}
			>
				<path
					d='M14.66.933L12.76 2.834l7.519 7.52H.616v2.808h19.662l-7.52 7.52 1.902 1.9 10.825-10.824z'
					// If the button is disabled, set arrow color to grey. If enabled, set to white.
					fill={disabled ? colors.bisGreySilver : colors.white}
					fillRule='evenodd'
				/>
			</svg>
		);
	}

}

